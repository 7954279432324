import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import image from "../images/intro.svg"
import ogImage from "../images/intro-og-image.png"
import style from "./intro.module.css"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query getDefinitions {
      allMarkdownRemark(
        sort: { order: ASC, fields: frontmatter___title }
        filter: { frontmatter: { draft: { ne: true }, featured: { eq: true } } }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="New to online advertising?"
        description="Learn the basics with Adlexicon"
        image={ogImage}
      />
      <div className={style.intro}>
        <div className={style.textContainer}>
          <h1>New to online advertising?</h1>
          <img
            className="u-mobile-only"
            src={image}
            alt=""
            role="presentation"
          />
          <p>Learn the basics of online advertising with&nbsp;Adlexicon.</p>

          <p class="lead">
            <strong>Start learning</strong>
          </p>

          <ul>
            {data.allMarkdownRemark.edges.map(edge => (
              <li>
                <Link to={edge.node.fields.slug}>
                  {edge.node.frontmatter.title}
                </Link>
              </li>
            ))}
          </ul>
          <p style={{ textAlign: `left` }}>
            Or check out all terms in the{" "}
            <Link to="/dictionary">dictionary</Link>
          </p>
        </div>
        <img
          className="u-non-mobile-only"
          src={image}
          alt=""
          role="presentation"
        />
      </div>
    </Layout>
  )
}

export default IndexPage
